
.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjusts content and footer positioning */
    min-height: 100vh; /* Ensures that .home covers the full viewport height */
    text-align: center;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    padding-top: 0;
    padding: 20px; /* Adds padding on all sides */
    background-color: #ffffff; /* Light background for better contrast */
}
/* Responsive adjustments */
@media (max-width: 600px) {
    .home-content {
        padding: 10px; /* Reduces padding on smaller screens */
    }
}

.home-content {
    /* Ensures .home-content takes up the correct width and can grow */
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    flex: 1; /* Allows .home-content to grow and fill the space */
}

.issue-label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
    margin-right: 10px; /* Add some space between the label and the input */
    white-space: nowrap; /* Ensure the label does not wrap */
    color: #2c3e50; /* Dark color for better readability */
}

.issue-input-container {
    /* Ensure this container takes full width to align the buttons underneath correctly */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns children (label and input) to the start of the flex container */
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: flex-start; /* Aligns buttons to the start */
    gap: 10px; /* Adjust the gap between buttons as needed */
    width: calc(100% - 40%); /* Subtract the width of the label from 100% */
    margin-left: 25%; /* Push the button container right by the width of the label */
}


.issue-input {
    flex-grow: 1; /* Allow the input to take up the remaining space */
    padding: 10px;
    border: 2px solid #3498db;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #333;
    background-color: #f7f7f7;
}

.issue-label {
    margin-right: 10px;
    white-space: nowrap;
    /* Align the text vertically */
    line-height: 40px; /* Adjust line-height to match the input height or as needed for alignment */
    height: 40px; /* Ensure label height matches input field height */
}

.issue-input {
    flex-grow: 1;
    padding: 10px;
    border: 2px solid #3498db;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #333;
    background-color: #f7f7f7;
    /* Match height with the label */
    line-height: normal; /* Reset to normal to align with the height of the input */
}

.cta-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.3s ease;
    color: white; /* White text for the buttons */
}

.cta-button.solo {
    background-color: #2ecc71; /* Bright green */
}

.cta-button.duo {
    background-color: #3498db; /* Bright blue */
}

.cta-button:hover, .cta-button:focus {
    transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
    .issue-input,
    .cta-button {
        width: 90%;
        margin-bottom: 10px;
    }

    .button-container {
        flex-direction: column;
    }
}

.verdikta-logo {
    width: 200px;
    height: 200px;
}

/* Ensure the intro paragraph has appropriate spacing and styling */
.intro-paragraph {
    font-size: 1rem; /* Adjust font size as needed */
    line-height: 1.5; /* Adjust line height for readability */
    text-align: justify; /* Align text to the left for better readability */
}

/* Target the new paragraph class for separation */
.separation-paragraph {
    font-size: 1rem; /* Adjust font size as needed */
    text-align:justify; /* Align text to the left for better readability */
    margin-top: 2rem; /* Adds spacing above this paragraph */
    padding-top: 1rem; /* Extra padding at the top */
    border-top: 3px solid #cccccc; /* Now using a darker grey for visibility */
}

.home-footer {
    width: 100%; /* Ensure the footer extends the full width */
    margin: 0; /* Remove any default margin */
    padding: 2px 0; /* Adjust padding as needed but remove horizontal padding */
    text-align: center;
    background-color: #f1f1f1; /* Light grey background for the footer */
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Subtle shadow at the top of the footer */
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

.home-footer p {
    color: #333; /* Dark grey color for text */
    margin: 10px 0; /* Keeps space between paragraphs, if you have more than one */
}

.notification-invitation {
    margin-top: 2rem; /* Adjusts spacing above the paragraph */
    text-align: center; /* Centers the text */
    padding: 20px ; /* Adds padding above and below for separation */
}
