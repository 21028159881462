/* Updated styles based on the original color palette */
.disclaimer {
  font-family: 'Arial', sans-serif;
  background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
  line-height: 1.6;
  color: #FFFFFF; /* Text Color (Clean White for contrast) */
  margin: 20px;
}

.container {
  padding: 20px;
}

.disclaimer-container h2, .disclaimer-container h3 {
  font-family: 'Roboto', 'Arial', sans-serif;
  line-height: 1.8;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: bold;
  text-align: left;
  color: #0A74DA; /* Primary Color (Trust Blue) */
}

.AppDisclaimer {
  text-align: left;
  background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
}

html {
  background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
} 

.about-container {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 20px;
padding: 20px;
background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
}

.content-rectangle {
background-color: #FFFFFF; /* Lightened for contrast and readability */
padding: 50px;
border-radius: 10px;
border: 1px solid #7F8C8D; /* Secondary Color (Neutral Grey) */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
transition: all 0.3s ease;
}

.content-rectangle:hover {
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.disclaimer-container a {
  color: #2ECC71; /* Accent Color 1 (Innovation Green) */
  text-decoration: underline;
}

.disclaimer-container a:hover {
  color: #24b059; /* Accent Color 2 (Darker shade of Innovation Green for hover) */
}
