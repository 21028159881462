.dashboard {
  background-color: #7F8C8D; /* Neutral Grey */
  color: #FFFFFF; /* Clean White for contrast */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.dashboard h2, .dashboard h3, .dashboard h4 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}

.table-wrapper {
  width: 80%;
  max-width: 90%; /* assuming Disputes table width is 90%. Adjust this if necessary. */
  margin: 0 auto; /* this will center the wrapper on the page */
  padding: 20px;
  border-radius: 10px;
}

.wallet-info {
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  padding: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 90%;
  border-radius: 10px;
}

.disputes, .verdicts {
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  padding: 20px;
  margin-bottom: 40px;
  width: 90%;
  border-radius: 10px;
}

.contracts-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  padding: 20px;
  margin-bottom: 40px;
  width: 70%;
  border-radius: 10px;
}

.contracts-table table {
  width: 87%; /* adjust as necessary to center the Contracts table within the wrapper */
}

.contracts-table th, .disputes th{
  text-align: center;
  padding: 12px;
  border: 1px solid #0A74DA; /* Trust Blue for table borders */
  background-color: #2ECC71; /* Innovation Green */
  color: #FFFFFF; /* Clean White for table headers */
  font-weight: 500;
}

.contracts-table td, .disputes td {
  text-align: center;
  padding: 12px;
  border: 1px solid #0A74DA; /* Trust Blue for table borders */
  background-color: #f4f4f4; /* Light grayish-white */
  color: #333; /* Dark text for contrast against the light background */
  font-weight: 500;
  /* ... other styles ... */
}

.delete-button {
  background-color: #E74C3C; /* Alert Red for delete buttons */
  color: #FFFFFF; /* Clean White for delete button text */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.2s all;
}

.delete-button:hover {
  background-color: #C0392B; /* Darker shade of Alert Red for hover */
}

/* Unvisited Link Color */
.dashboard a {
  color: #0A74DA; /* Trust Blue for unvisited links */
  text-decoration: none; /* Remove the underline for a cleaner look */
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

/* Visited Link Color */
.dashboard a:visited {
  color: #7F8C8D; /* Neutral Grey for visited links */
}

/* Hover effect to give user feedback when they hover over a link */
.dashboard a:hover {
  color: #2ECC71; /* Innovation Green for hover effect */
}
.contracts-table {
  width: 90%; /* Adjust this percentage to your preference */
  margin-bottom: 50px; /* Adjust this value to increase or decrease the space between the contracts table and the element below it */
}

