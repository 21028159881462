/* DisputeDetail.css */

.dispute-detail {
    background-color: #d9dee9;
    border-radius: 15px;
    padding: 30px;
    margin: 20px auto;
    max-width: 800px;
  }
  
  .dispute-detail h1 {
    margin-bottom: 10px;
  }
  
  .dispute-detail h2 {
    margin-bottom: 20px;
  }
  
  .dispute-detail h3 {
    margin-bottom: 20px;
  }
  
  .dispute-detail p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  