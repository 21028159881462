@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');


.solo-dispute {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7F8C8D; /* Neutral Grey */
  min-height: 100vh;
  padding: 50px;
  color: #FFFFFF; /* Clean White for contrast */
}


.page-title {
  font-size: 2.5rem; /* adjust size as needed */
  color: #2ECC71; /* a vibrant, attention-grabbing color */
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5em; /* adds some white space below the title */
  font-weight: 600; /* bolder font for the title */
}

.page-subtitle {
  font-size: 1.2rem;
  color: #ffffff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2em; /* more white space around subtitle for a cleaner look */
  font-weight: 400; /* regular weight for the subtitle */
  max-width: 800px; /* max-width to maintain readability */
  margin-left: auto; /* these two lines center the subtitle */
  margin-right: auto;
}


.solo-dispute form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  width: 80%;
  padding: 20px;
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12);
}

.solo-dispute label {
  width: 100%;
  padding: 10px 0;
  text-align: left;
}

.solo-dispute input[type="text"],
.solo-dispute input,
.solo-dispute textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #0A74DA; /* Trust Blue for inputs border */
  box-sizing: border-box;
  background-color: #FFFFFF; /* Clean White for inputs background */
  color: #2C3E50; /* Deep Black for input text */
}

/* Add focus styles to improve UX */
.solo-dispute input:focus,
.solo-dispute textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3498db; /* Trust Blue for focus */
}


.solo-dispute textarea {
  min-height: 100px;
}

.solo-dispute button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #2ECC71; /* Innovation Green */
  color: #FFFFFF; /* Clean White for button text */
  cursor: pointer;
}

.solo-entry-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

textarea[name="disputeDescription"] {
 flex: 5; 
}

.questions-container {
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
}

.submit-button, .submit-nq-button {
  width: 100% ;
  height: 40px;
  display: flex; /* Flexbox to center the text */
  justify-content: center;   /* Center text horizontally */
  align-items: center;       /* Center text vertically */
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #2ECC71; /* Innovation Green */
  color: #ffffff; /* Clean White for button text */
  cursor: pointer;
  overflow: visible;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.submit-nq-button {
  font-size: 14px;
}

.submit-button:hover {
  background-color: #24b059; /* Darker shade of Innovation Green for hover */
}


.loading-button {
  background-color: #888;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { background-color: #888; }
  50% { background-color: #aaa; }
  100% { background-color: #888; }
}

.question-input-pair {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-field {
  width: 100%;
}

.input-field input {
  width: 100%;
}

.questions-container h2 {
  color: #FFFFFF; /* Clean White for the headings */
}

@media (max-width: 768px) {
  .solo-dispute form {
    width: 95%; /* Allow the form to be more responsive on mobile devices */
  }
  .submit-button, .submit-nq-button {
    width: 45%; /* Adjust for smaller screens */
  }
}

.submit-button:disabled,
.submit-nq-button:disabled {
  background-color: #95a5a6; /* Neutral color for disabled state */
  cursor: not-allowed;
}

.submit-button:not(:disabled):hover,
.submit-nq-button:not(:disabled):hover {
  background-color: #27ae60; /* Slightly lighter green for hover */
}

.form-guidance {
  font-weight: bold;
  font-size: 1rem;
  color: #E0E0E0; /* Light grey for better readability */
  margin-bottom: 10px; /* Ensure space between guidance text and input */
  text-align: justify;
}
