.contract-entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7F8C8D; /* Neutral Grey */
  min-height: 100vh;
  padding: 50px;
  color: #FFFFFF; /* Clean White for contrast */
}

.contract-entry form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  width: 80%;
  padding: 20px;
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12);
}

.contract-entry label {
  width: 100%;
  padding: 10px 0;
  text-align: left;
}

.contract-entry input[type="text"],
.contract-entry textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0A74DA; /* Trust Blue for inputs border */
  box-sizing: border-box;
  background-color: #FFFFFF; /* Clean White for inputs background */
  color: #2C3E50; /* Deep Black for input text */
}

.contract-entry textarea {
  min-height: 100px;
}

.contract-entry button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #2ECC71; /* Innovation Green */
  color: #FFFFFF; /* Clean White for button text */
  cursor: pointer;
}

.contract-entry-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

textarea[name="contract_description"] {
  flex: 5; 
}

.detail-value {
  white-space: pre-wrap; /* This will preserve whitespaces and line breaks */
  /* ... other styles if any ... */
}