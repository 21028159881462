.instant-verdict-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #7F8C8D; /* Secondary Color (Neutral Grey) */
    min-height: 100vh;
    padding: 10px 50px 50px 50px; /* Reduced the top padding */
    color: #FFFFFF; /* Text Color (Clean White for contrast) */
}

.verdict-text {
    padding: 20px;
    background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
    border-radius: 5px;
    width: fit-content;
    font-weight: bold;
}

.instant-verdict-display h1, .instant-verdict-display h2, .instant-verdict-display h3, .instant-verdict-display h4 {
    color: #FFFFFF; /* Text Color (Clean White for contrast) */
    margin-bottom: 10px;
}

.instant-verdict-display p {
    margin-bottom: 10px;
}

.instant-verdict-display ul {
    list-style-type: none;
    padding: 0;
}

.instant-verdict-display li {
    background-color: rgba(44, 62, 80, 0.9); /* Background Color (Deep Black) */
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}
