.arbitration-success-container {
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

h2 {
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
}

.links-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.success-link {
    font-size: 18px;
    text-decoration: none;
    color: #007BFF;
    padding: 10px 15px;
    border: 1px solid #007BFF;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #007BFF;
        color: #fff;
    }
}
