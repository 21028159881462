.navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    background-color: transparent; /* Set background-color to transparent */
    color: #fff;
  }

 /* The navbar code below implements the dark navigation bar */
.navbar {
    background-color: #333;
    color: #fff;
    /* overflow: hidden; */
 }

.navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    transition: all 0.2s ease;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
    border-radius: 4px;
}
  
  .navbar-container {
    display: flex;
    align-items: center;
  }

.navbar__logo h2 {
    margin: 0;
}

.navbar__links a {
    margin: 0 10px;
    text-decoration: none;
    color: black;
}
.connect-wallet-button {
    padding: 10px 20px;
    margin-left: auto;
    border: none;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .connect-wallet-button:hover {
    background-color: #555;
  }
  

.navbar-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  margin-right: auto;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-menu li {
  margin-right: 20px;
}

.navbar-menu li:last-child {
  margin-right: 20;
}
  
  .connect-wallet-button:hover {
    background-color: #555;
  }


.navbar-items {
    display: flex;
    align-items: center; /* Align items vertically */
    flex-wrap: wrap; /* Allow items to wrap if there's not enough room */
    gap: 10px; /* Adds a gap between navbar items */

  }

  .item .dropdown {
    margin-right: 20px; /* Uniform margin for all items */
  }
  
  .connect-wallet-wrapper {
    margin-left: auto;
  }

  .disabled-link {
    pointer-events: none;
    color: #cccccc;
  }

.dropbtn {
  background-color: #333; 
  color: white; 
  padding: 16px; 
  font-size: 16px; 
  border: none; 
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 5px;
  
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  margin-right: 20px;
  display: inline-block; /* Needed for proper positioning of dropdown content */
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  width: auto; /* Adjust width to fit content */
  /* Ensure box sizing includes padding and borders */
  box-sizing: border-box;
  display: none;
  position: absolute;
  background-color: #fcfcfc; /* Light grey background */
  min-width: 100px; /* Set a min-width if necessary */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add a shadow effect */
  z-index: 10; /* Place it on top of other elements */
}

/* Links inside the dropdown */
.dropdown-content .item {
  color: black; /* Black text color */
  padding: 12px 0px; /* Some padding */
  text-decoration: none; /* No underline */
  display: block; /* Display as block */
  width: 100%; /* Use the full width */
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  word-wrap: break-word; /* Break long words */
}

/* Change color of dropdown links on hover */
.dropdown-content .item:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
   background-color: #ddd;
   color: black;
   border-radius: 4px;
}

.navbar a, .dropbtn {
  padding: 10px 12px; /* Slightly reduce padding */
  font-size: 14px; /* Slightly reduce font size */
}

@media (max-width: 768px) {
  .navbar a, .dropbtn {
      padding: 8px 10px; /* Even smaller padding for very small screens */
      font-size: 12px; /* Smaller font size for very small screens */
  }
}