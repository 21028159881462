.arbitration-form-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .arbitration-form-question {
    margin-bottom: 20px;
  }
  
  .arbitration-form-question textarea {
    width: 100%;
    min-height: 100px;
  }
  
  /* Add the styles for the loading button */
.arbitration-form-container .loading-button {
  background-color: #888;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { background-color: #888; }
  50% { background-color: #aaa; }
  100% { background-color: #888; }
}
