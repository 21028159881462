.verdict-display {
    background-color: #7F8C8D; /* Neutral Grey */
    color: #FFFFFF; /* Clean White for contrast */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.verdict-display h1, .verdict-display h2, .verdict-display h3, .verdict-display h4 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
}

.dispute-title {
    color: #0A74DA; /* Trust Blue for the main heading */
    font-size: 1.8em;
}

.dispute-id {
    color: #2ECC71; /* Innovation Green for subheadings */
    margin-bottom: 10px;
    font-size: 1.2em;
}

.verdict-text {
    font-weight: bold;
    font-size: 1.3em;
    border: 2px solid #2ECC71; /* Border color as Innovation Green */
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

h4 {
    color: #0A74DA; /* Trust Blue for sub-subheadings */
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li p {
    margin: 0;
    padding: 5px 0;
    color: #FFFFFF; /* Clean White for contrast */
}

strong {
    font-weight: bold;
    color: #FFFFFF; /* Clean White for contrast */
}

ul li {
    border: 1px solid #0A74DA; /* Trust Blue for list item borders */
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
}

.share-buttons {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the gap between buttons as needed */
    margin-top: 20px; /* Spacing above the share buttons */
  }
  
  .share-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0A74DA; /* Trust Blue for buttons */
    color: #FFFFFF; /* Clean White for button text */
  }
  
  .share-buttons button:hover {
    background-color: #2ECC71; /* Innovation Green for hover effect */
  }

  .share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1DA1F2; /* Twitter's brand color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .share-button .fa-twitter {
    margin-right: 10px;
  }
  
  
  .verdikta-logo-container {
    position: relative; /* Set the position context for absolute positioning */
    width: 100%;
    text-align: center; /* Center the logo container */
    margin-bottom: 20px; /* Space between logo and title */
  }
  
  .verdikta-logo {
    max-width: 400px; /* Example size, adjust as needed */
    height: auto; /* Keep the aspect ratio */
    opacity: 0.5; /* Make the image semi-transparent */
  }
  
  .logo-overlay-text {
    position: absolute; /* Position the text over the image */
    top: 70%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the positioning to truly center */
    color: white; /* Color of the text */
    /* Add more styling as necessary */
  }
  