/* ContractDetail.css */
.contract-detail-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .detail-item {
    margin: 10px 0;
  }
  
  .detail-title {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .detail-value {
    font-size: 1em;
  }
  
  .contract-title {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  