.defendant-rebuttal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7F8C8D; /* Neutral Grey */
  min-height: 100vh;
  padding: 50px;
  color: #FFFFFF; /* Clean White for contrast */
}

.defendant-rebuttal-form form {
  display: flex;
  flex-direction: column;
  align-items: stretch;  
  width: 80%;
  padding: 20px;
  background-color: rgba(44, 62, 80, 0.9); /* Deep Black with some transparency */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12);
}

.defendant-rebuttal-form label {
  width: 100%;
  padding: 10px 0;
  text-align: left;
}

.defendant-rebuttal-form input[type="text"],
.defendant-rebuttal-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0A74DA; /* Trust Blue for inputs border */
  box-sizing: border-box; 
  background-color: #FFFFFF; /* Clean White for inputs background */
  color: #2C3E50; /* Deep Black for input text */
}

.defendant-rebuttal-form textarea {
  min-height: 100px;
  min-width: 100%;
  padding: 10px;
}

.defendant-rebuttal-form button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #2ECC71; /* Innovation Green */
  color: #FFFFFF; /* Clean White for button text */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.defendant-rebuttal-form button:hover {
  background-color: #24b059; /* Darker shade of Innovation Green for hover */
}

.loading-button {
  background-color: #888;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { background-color: #888; }
  50% { background-color: #aaa; }
  100% { background-color: #888; }
}
